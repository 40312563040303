<template>
  <div class="page-box">
    <header-bar
      :swiperActiveIndex="2"
      white
    />
    <div class="banner">
      <div>
        <div class="en">ZHANG<span>WAN</span></div>
        <div class="block">
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div class="title2">掌玩荣誉</div>
        <div class="content2">不忘初心 方得始终</div>
      </div>
    </div>
    <div class="page-content">
      <div class="fazhan-box">
        <div class="title">企业荣誉</div>
        <div class="rongyu-box">
          <p class="xtitle">奖项荣誉</p>
          <p class="text">荣获2024快手·磁力引擎“恒星奖”</p>
          <p class="img">
            <img src="/rongyuimg/hxj.png">
          </p>

          <p class="xtitle">奖项荣誉</p>
          <p class="text">荣获2023年腾讯广告“年度贡献卓越广告主”与“最佳创新小游戏广告主”向阳奖</p>
          <p class="img">
            <img src="/rongyuimg/xiangyang.png">
          </p>

          <p class="xtitle">奖项荣誉</p>
          <p class="text">荣获2023腾讯广告 “年度最佳用户增长奖”与“小说年度女频突破”奖</p>
          <p class="img">
            <img src="/rongyuimg/yhzzj.png">
          </p>

          <p class="xtitle">奖项荣誉</p>
          <p class="text">2022年被点众科技授予，“最佳合作伙伴”称号</p>
          <p class="img">
            <img src="/rongyuimg/new/ry9.jpeg">
          </p>

          <p class="xtitle">奖项荣誉</p>
          <p class="text">2021年荣登腾讯广告自媒体王者榜，荣获“最强充值王”称号</p>
          <p class="img">
            <img src="/rongyuimg/new/ry4.png">
          </p>

          <p class="xtitle">奖项荣誉</p>
          <p class="text">成为2021年掌中云小说平台的年度战略合作伙伴，并在巨量引擎投放平台做出优异成绩</p>
          <p class="img">
            <img src="/rongyuimg/new/ry8.png">
          </p>
          <p class="xtitle">奖项荣誉</p>
          <p class="text">荣登2021腾讯广告自媒体王者榜，荣获“腾讯广告自媒体小说消耗王者“与“链路突破运营奖”</p>
          <p class="img">
            <img src="/rongyuimg/new/ry7.png">
          </p>
          <p class="xtitle">奖项荣誉</p>
          <p class="text">在2020年与巨量引擎深度合作，创造出优异成绩，获得“阅·势如虹”的殊荣</p>
          <p class="img">
            <img src="/rongyuimg/new/ry5.png">
          </p>
          <p class="xtitle">奖项荣誉</p>
          <p class="text">荣登2020腾讯广告自媒体王者榜，荣获“腾讯广告自媒体小说消耗王者“与“腾讯广告自媒体小说测书王者”</p>
          <p class="img">
            <img src="/rongyuimg/new/ry6.png">
          </p>
          <p class="xtitle">奖项荣誉</p>
          <p class="text">2020年8月参与腾讯广告年度峰会，获得腾讯广告授予“自媒体小说测书王者”和“自媒体小说消耗王者”两座奖项</p>
          <p class="img">
            <img
              src="/rongyuimg/ry9.jpeg"
              @click="test"
            >
            <img src="/rongyuimg/ry8.jpeg">
            <img src="/rongyuimg/ry10.jpeg">
          </p>
          <p class="xtitle">奖项荣誉</p>
          <p class="text">2020年成为掌阳光书城小说平台的年度最佳合作伙伴</p>
          <p class="img">
            <img src="/rongyuimg/new/ry3.png">
          </p>

          <p class="xtitle">奖项荣誉</p>
          <p class="text">荣登2019腾讯广告自媒体王者榜,荣获2019年“腾讯小说增速王者”荣誉</p>
          <p class="img">
            <img
              src="/rongyuimg/new/ry2.jpg"
              @click="test"
            >
          </p>

          <p class="xtitle">奖项荣誉</p>
          <p class="text">2020年9月9日，广州简知授予掌玩网络最佳战略合作伙伴称号</p>
          <p class="img">
            <img src="/rongyuimg/ry12.jpeg">
          </p>
          <p class="xtitle">奖项荣誉</p>
          <p class="text">2019年度获得江苏文旭授予掌玩网络优质合作伙伴称号</p>
          <p class="img">
            <img src="/rongyuimg/ry11.jpeg">
          </p>
          <p class="xtitle">奖项荣誉</p>
          <p class="text">2014至2020年间，陆续获得大量软件著作权证书以及出版物经营许可证，还在2018年度被评为“国家级高新技术企业”</p>
          <p class="img">
            <img src="/rongyuimg/ry3.png">
            <img src="/rongyuimg/ry1.png">
            <img src="/rongyuimg/ry4.png">
          </p>
        </div>
      </div>
    </div>

    <footer-bar />
  </div>
</template>
<script>
export default {
  methods: {
    test() {
      console.log(123123)
    }
  }
}
</script>
<style lang="scss" scoped>
.banner {
  position: relative;
  height: 100%;
  background: url(/bg/8.jpg);
  background-position: center center;
}
.page-content {
  display: flex;
  justify-content: center;
}
.fazhan-box {
  width: 70%;
  max-width: 1160px;
  margin-top: 93px;
  .title {
    font-size: 34px;
    margin-bottom: 30px;
  }
  .rongyu-box {
    margin-bottom: 74px;
    .xtitle {
      font-size: 20px;
      color: #f02e0e;
    }
    .text {
      margin-top: 37px;
      margin-bottom: 10px;
    }

    img {
      width: 30%;
      padding: 1% 1% 1% 0;
    }
  }
}
</style>